import React from "react";
import PropTypes from "prop-types";
import Head from "../../components/Head/Head";
import Header from "../../components/Header/Header";
import * as Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux'
import PricingCourse2Layout from "../../layouts/pricing/question/course2";
import putPricingState from '../../procedures/pricing/putPricingState'
import packPricingObject from '../../functions/pricing/packPricingObject'
import PRICING_ACTIONS from '../../redux/actions/Pricing'
import HeaderPlaceholder from "../../components/common/HeaderPlaceholder/HeaderPlaceholder";
import { navigate } from "gatsby";

const NewLessonPage = ({ location }) => {
  const dispatch = useDispatch()
  const Pricing = useSelector((state) => state.Pricing)

  const setNewLesson = () => {
    const setAnswer = PRICING_ACTIONS.setNewLesson(1)
    dispatch(setAnswer)

    let pricingObject = packPricingObject(Pricing)
    pricingObject.newLesson = 1
    const idCookie = Cookies.getJSON('opz-pricing-id')
    const id = idCookie ? idCookie.id : null
    if (!id) {
      return () => {
      }
    }
    putPricingState(Pricing.id, pricingObject);
    navigate('/cost-to-make-an-app/expert-consult');
  }

  return (
    <>
      <Head />
      <Header pageId={location.pathname} />
      <section className="full-section">
        <HeaderPlaceholder />
        <PricingCourse2Layout />
      </section>
    </>
  );
};

NewLessonPage.propTypes = {
  location: PropTypes.shape({
    key: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
  }).isRequired,
};

export default NewLessonPage;
