import React,{useState} from 'react'
import { navigate } from 'gatsby'
import clsx from 'clsx'
import * as Cookies from 'js-cookie';
import Swal from "sweetalert2";
import GoBack from '../../../../components/pricing/goBack'
import { useDispatch, useSelector } from 'react-redux'
import Video from "../../../../components/Video/video";
import putPricingState from '../../../../procedures/pricing/putPricingState'
import packPricingObject from '../../../../functions/pricing/packPricingObject'
import ContactUs from '../../../../components/contactUs'
import Register from '../../../../components/Register'
import PRICING_ACTIONS from '../../../../redux/actions/Pricing'
import styles2 from '../../../../components/contactUs/styles.module.css'
import './styles.css'

const PricingCourse2Layout = () => {
  const [registerForm , setRegisterForm] = useState(false);
  const dispatch = useDispatch()
  const Pricing = useSelector((state) => state.Pricing)

  const setCourse2 = () => {
    if(Pricing.verifyCount === 0){
      setRegisterForm(true);
  }else{
    const setAnswer = PRICING_ACTIONS.setCourse2(1)
      dispatch(setAnswer)

      let pricingObject = packPricingObject(Pricing)
      pricingObject.course2 = 1
      const idCookie = Cookies.getJSON('opz-pricing-id')
      const id = idCookie ? idCookie.id : null
      if (!id) {
        return () => {
        }
      }
      putPricingState(Pricing.id, pricingObject);
      Swal.fire(
        'Congratulation',
        'You successfully register for the course',
        'success'
      )
      navigate('/cost-to-make-an-app/expert-consult');
  }
  }

  return (
    <>
      <div className="container">
        <center className={clsx('container', styles2.mycontainer2)}>
          <div className="section__p d-flex justify-content-center flex-column">
            <div className="container">
              <h1 className="section__h1">The Funding Formula</h1>
              <p>
                If you have a product, you will eventually have to compete at a national level
                unlike local companies such as doctors and groceries and for this
                battle you need more than just money and a good product.
              </p>
              <p>
                This video explains why most large companies such as
                Amazon, Google, Tesla and Facebook have investors.
              </p>
            </div>
            <Video src={'https://media.opzoom.com/app.opzoom.com/funding.path/intro.mp4'} type={'video/mp4'}/>
          </div>
        </center>
        <center className={clsx('container', styles2.mycontainer2)}>
          <div className="section__p d-flex justify-content-center flex-column">
            <h1 className="section__h1">The path to Funding</h1>
            <p>
              This path will get you funded by helping you with the critical information
              and resources when you need it.
            </p>
            <p>
              This path has the following for you:
              <ol>
                <li><b>Videos</b> that help you with all the information you need to be funded.</li>
                <li><b>Workbooks/Exercises</b> to help you figure out your strategy to find investors, and make an attractive offer.</li>
                <li><b>Live interviews</b> with experts where you can ask questions.</li>
              </ol>
            </p>
            <p>
              OpZoom can help you with 2 financial resources:
              <ol>
                <li>The <b>StartApp Grant</b> provides assistance in planning out your app for quick success.
                This is meant to help when you are starting out.</li>
                <li>The <b>StartApp Fund</b> provides up to $100,000 for every startup. This is meant
                to help when you are sure of the value of the idea you are working on and you see a
                  clear path to success.</li>
              </ol>
            </p>
            <p>
              The next video gives you a summary of your path to getting funded by investors.
            </p>

            {registerForm  && <Register page="course2"/>}
            {!registerForm && <center>
              <button type="button" className="button" onClick={() => {
                setCourse2()
              }}>
                Start: Path to Funding
              </button>
            </center>}
          </div>
        </center>
        <center className={clsx('container', styles2.mycontainer2)}>
          <div className="section__p d-flex justify-content-center flex-column">
            <center>
              <h1 className="section__h1">Get to Success Fast</h1>
              <p>
                If you want to quickly get to a profitable business with your app,
                you need someone to guide you and prevent all those painful mistakes.
              </p>

              <button type="button" className="button" onClick={() => {
                navigate('/cost-to-make-an-app/expert-consult');
              }}>
                Talk with our expert
              </button>
            </center>
          </div>
        </center>

        {(Pricing.verifyCount === 0) && <ContactUs/>}

      </div>

      <GoBack prevQuestionId={'course1'}/>
    </>
  )
}
export default PricingCourse2Layout
